import React, { useEffect, useState } from "react";
import { CameraWrapper } from "./styled";
import Layout from "../../componet/Layout";
import { GetJoin, insertAuth } from "../../service/challeng";
import { Link, useNavigate, useParams } from "react-router-dom";
import Alert from "../../componet/Alert";
import { useSetRecoilState } from "recoil";
import { loadingAtom } from "../../store/loading";

const Camera = () => {
  const [hasContent] = useState(false);
  const nav = useNavigate();
  const params = useParams();
  const setLoading = useSetRecoilState(loadingAtom);

  const [ld, setLd] = useState(false);
  const [thumbnail, setThumbnail] = useState("");
  const [preview, setPreview] = useState("");

  const [content, setContent] = useState("");

  const [item, setItem] = useState();

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const uploadFile = (e) => {
    console.log(e.target.files);
    setThumbnail(e.target.files);
    if (e.target.files) {
      setPreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  const [disable, setDisable] = useState(false);

  useEffect(() => {
    console.log(isMobile);
    console.log("navigator.userAgent", navigator.userAgent);

    if (!isMobile && item?.authMethod == "capture") {
      setDisable(true);
    }
  }, [item]);

  const redirect = () => {
    window.location.href = "/";
  };

  const back = () => {
    window.history.back();
  };

  useEffect(() => {
    setLoading(true);
    GetJoin({
      joinId: params?.ID,
    })
      .then((res) => {
        console.log(res);
        if (res.data.code === "200") {
          if (res.data.body.isTodayAuth) {
            toggle();
          }
          setItem(res.data.body);
        } else {
          setItem(null);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [params?.ID]);

  const submit = () => {
    if (item?.remainNum < 0) {
      toggle5();
      return;
    }

    if (!preview) {
      toggle2();
      return;
    }
    if (!content) {
      toggle3();
      return;
    }

    setLoading(true);

    // setLoading(true);
    setLd(true);
    const fd = new FormData();
    fd.append("content", content);
    fd.append("joinId", params?.ID);

    Array.from(thumbnail).map((thumbnail, key) => {
      fd.append("image", thumbnail);
    });

    insertAuth(fd)
      .then((response) => {
        if (response.data.code == "200") {
          toggle4();
          // setLoading(false);
          // setSuccess(true);
        } else {
          alert(response.data.body.message);
          // setLoading(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        alert("서버 요청 오류!");
        // setLoading(false);
        setLoading(false);
      });
  };

  const [active, setActive] = useState(false);
  const toggle = () => {
    setActive((p) => !p);
  };

  const [active2, setActive2] = useState(false);
  const toggle2 = () => {
    setActive2((p) => !p);
  };

  const [active3, setActive3] = useState(false);
  const toggle3 = () => {
    setActive3((p) => !p);
  };

  const [active4, setActive4] = useState(false);
  const toggle4 = () => {
    setActive4((p) => !p);
  };

  const [active5, setActive5] = useState(false);
  const toggle5 = () => {
    setActive5((p) => !p);
  };

  return (
    <div>
      <Layout footerType={"none"} headerTitle={item?.challengeTitle}>
        <CameraWrapper>
          <div className="wrapper">
            <div className="photo-wrap">
              <h2 className="title">인증하기</h2>
              <label
                className="photo-box"
                id={disable ? "disable" : ""}
                style={preview ? { backgroundImage: `url(${preview})` } : null}
                htmlFor="imgUpload"
              >
                {/* {!item?.authMethod ? (
                  preview ? null : (
                    <div className="photo-text-box" htmlFor="imgUpload">
                      <img src="/images/icon-photo.svg" alt="" />
                      <p> </p>
                    </div>
                  )
                ) : null} */}
                {item?.authMethod == "upload" ? (
                  preview ? null : (
                    <div className="photo-text-box">
                      <img src="/images/icon-photo.svg" alt="" />
                      <p>인증샷 업로드 하기</p>
                    </div>
                  )
                ) : null}
                {item?.authMethod == "capture" ? (
                  preview ? null : (
                    <div className="photo-text-box" htmlFor="imgUpload">
                      <img src="/images/icon-camera.svg" alt="" />
                      <p>촬영 하기</p>
                    </div>
                  )
                ) : null}

                {item?.authMethod == "upload" ? (
                  <input
                    type="file"
                    accept="image/*"
                    id="imgUpload"
                    onChange={uploadFile}
                  />
                ) : null}
                {item?.authMethod == "capture" ? (
                  <input
                    type="file"
                    accept="image/*"
                    id="imgUpload"
                    capture="camera"
                    onChange={uploadFile}
                  />
                ) : null}
                {!item?.authMethod ? (
                  <input
                    type="file"
                    accept="image/*"
                    id="imgUpload"
                    onChange={uploadFile}
                  />
                ) : null}
              </label>
              <div className="textarea-box">
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  placeholder="내용을 입력해 주세요"
                  onChange={(e) => setContent(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="btn-box">
              <div className="btn-padding">
                <button
                  onClick={() => nav("/esgauthlist")}
                  className="back-btn"
                >
                  취소하기
                </button>
                <button onClick={() => (ld ? null : submit())}>
                  {ld ? "인증중" : "인증하기"}
                </button>
              </div>
              {/* <div className="bar"></div> */}
            </div>
          </div>
          <Alert
            active={active}
            toggle={toggle}
            title={"오늘은 이미 인증하기를 완료했습니다."}
            oneEvent={true}
            event={redirect}
          />
          <Alert
            active={active2}
            toggle={toggle2}
            title={"인증샷을 업로드해주세요."}
            oneEvent={true}
          />
          <Alert
            active={active3}
            toggle={toggle3}
            title={"내용을 입력해주세요."}
            oneEvent={true}
          />
          <Alert
            active={active4}
            toggle={toggle4}
            title={"관리자 검수 후 인증이 완료됩니다."}
            event={back}
            oneEvent={true}
          />
          <Alert
            active={active5}
            toggle={toggle5}
            title={"인증 횟수를 모두 채웠습니다."}
            oneEvent={true}
          />
        </CameraWrapper>
      </Layout>
    </div>
  );
};

export default Camera;
